<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="mstTable"
          title="메시지 타입"
          tableId="mstTable"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :usePaging="false"
          :hideBottom="true"
          :columns="mstGrid.columns"
          :data="mstGrid.data"
          selection="single"
          rowKey="mstCd"
          :isExcelDown="false"
          @rowClick="getDtls"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
            <c-btn label="" icon="add" @btnClicked="addRowMst" v-if="editable && isManager" />
            <c-btn label="" icon="remove" @btnClicked="removeMst" v-if="editable && isManager" />
            <c-btn label="" icon="save" @btnClicked="saveMst" v-if="editable" />
            </q-btn-group>
          </template>
        </c-table>
        <q-dialog v-model="prompt" persistent>
          <q-card style="min-width: 350px">
            <q-card-section>
              <div class="text-h6">메시지타입 코드</div>
            </q-card-section>
            <q-card-section class="q-pt-none">
              <q-input dense v-model="hiddenMstCd" autofocus @keyup.enter="setMst" />
            </q-card-section>
            <q-card-actions align="right" class="text-primary">
              <q-btn flat label="취소" @click="cancelMst" />
              <q-btn flat label="추가" @click="setMst" />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="dtlGrid"
          title="메시지 목록"
          tableId="dtlGrid"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="dtlGrid.columns"
          :data="dtlGrid.data"
          :isExcelDown="false"
          @rowClick="rowClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <q-form ref="editForm">
          <c-card title="상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable && isMstSelected && isManager" label="신규" icon="add" @btnClicked="addDtl" />
                <c-btn
                  v-if="editable && deleteable && isManager"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteDtl" />
                <c-btn
                  v-if="editable && saveable"
                  :url="dtlSaveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveDtl"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="false"
                  label="메시지코드"
                  name="msgCd"
                  v-model="data.msgCd">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="메시지"
                  name="msgVal"
                  v-model="data.msgVal">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  label="메시지-영문"
                  name="msgValEn"
                  v-model="data.msgValEn">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  label="메시지-일본어"
                  name="msgValJa"
                  v-model="data.msgValJa">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  label="메시지-중국어간체"
                  name="msgValZhCn"
                  v-model="data.msgValZhCn">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  label="메시지-중국어번체"
                  name="msgValZhTw"
                  v-model="data.msgValZhTw">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'lang-message',
  data() {
    return {
      prompt: false,
      hiddenMstCd: '',
      searchParam: {
        mstCd: '',
      },
      mstGrid: {
        columns: [
          {
            name: 'mstCd',
            field: 'mstCd',
            label: '코드',
            align: 'center',
            sortable: false,
          },
          {
            name: 'mstName',
            field: 'mstName',
            label: '메시지 타입',
            align: 'center',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      dtlGrid: {
        columns: [
          {
            name: 'msgCd',
            field: 'msgCd',
            label: '메시지코드',
            align: 'center',
            style: "width:120px",
            sortable: true,
          },
          {
            name: 'msgVal',
            field: 'msgVal',
            label: '메시지',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        mstCd: '',
        msgCd: '',
        msgVal: '',
        msgValEn: '',
        msgValJa: '',
        msgValZhCn: '',
        msgValZhTw: '',
        useFlag: 'Y',
      },
      saveType: 'POST',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      isDelete: false,
      isSave: false,
      isMstSelected: false,
      mstlistUrl: '',
      mstSaveUrl: '',
      mstDeleteUrl: '',
      dtllistUrl: '',
      dtlDetailUrl: '',
      dtlSaveUrl: '',
      dtlInsertUrl: '',
      dtlUpdateUrl: '',
      dtlDeleteUrl: '',
      isManager: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.isManager = (this.$store.getters.user.userId === 'U000000001');
      this.dataeditable = false;
      this.mstlistUrl = selectConfig.sys.msg.mst.list.url;
      this.mstSaveUrl = transactionConfig.sys.msg.mst.save.url;
      this.mstDeleteUrl = transactionConfig.sys.msg.mst.delete.url;
      this.dtllistUrl = selectConfig.sys.msg.dtl.list.url;
      this.dtlDetailUrl = selectConfig.sys.msg.dtl.get.url;
      this.dtlInsertUrl = transactionConfig.sys.msg.dtl.insert.url;
      this.dtlUpdateUrl = transactionConfig.sys.msg.dtl.update.url;
      this.dtlDeleteUrl = transactionConfig.sys.msg.dtl.delete.url;
      this.getMsts();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getMsts() {
      this.$http.url = this.mstlistUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.mstGrid.data = _result.data;
      },);
    },
    getDtls(row) {
      if (row.editFlag != 'C') {
        this.$http.url = this.$format(this.dtllistUrl, row.mstCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.dtlGrid.data = _result.data;
          this.isMstSelected = true;
          this.reset();
        },
        () => {
        });
      }
    },
    addRowMst() {
      this.rowRemoveSelect();
      this.dtlGrid.data = [];
      this.reset();
      this.prompt = true;
    },
    cancelMst() {
      this.hiddenMstCd = '';
      this.prompt = false;
    },
    setMst() {
      this.prompt = false;
      this.mstGrid.data.splice(0, 0, {
        editFlag: 'C',
        mstCd: this.hiddenMstCd,
        mstName: '',
        useFlag: 'Y',
      })
      this.hiddenMstCd = '';
    },
    saveMst() {
      let checkItem = ['mstCd', 'mstName']
      let isConti = true;
      this.$_.forEach(this.mstGrid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [라벨타입코드, 라벨타입명]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.mstGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.mstSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getMsts();
                this.rowRemoveSelect();
                this.dtlGrid.data = [];
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeMst() {
      let selectData = this.$refs['mstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.mstDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getMsts();
              this.rowRemoveSelect();
              this.dtlGrid.data = [];
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.$http.url = this.$format(this.dtlDetailUrl, row.msgCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.dtlSaveUrl = this.dtlUpdateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addDtl() {
      this.saveable = true;
      this.deleteable = false;
      this.dtlSaveUrl = this.dtlInsertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      let selectData = this.$refs['mstTable'].selected;
      this.data = {
        mstCd: selectData[0].mstCd,
        msgCd: '',
        lblVal: '',
        lblValEn: '',
        lblValJa: '',
        lblValZhCn: '',
        lblValZhTw: '',
        useFlag: 'Y',
      };
    },
    reset() {
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        msgCd: '',
        lblVal: '',
        lblValEn: '',
        lblValJa: '',
        lblValZhCn: '',
        lblValZhTw: '',
        useFlag: 'Y',
      };
    },
    saveDtl() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteDtl() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.dtlDeleteUrl, this.data.msgCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.reset();
            this.getDtls(this.$refs['mstTable'].selected[0]);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDtls(result.data);
      this.rowClick(result.data)
    },
  }
};
</script>
